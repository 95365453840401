import { useContext, useState } from "react";
import { MusicContext } from "@/contexts/MusicContext";
import { getContentArtwork } from "@/utils";
import {
  Box,
  CardMedia,
  IconButton,
  Typography,
  CircularProgress,
} from "@mui/material";
import { Pause, PlayArrow, ChatBubbleOutline } from "@mui/icons-material";
import ActiveContentDrawer from "./ActiveContentDrawer";

export default function FeaturedAudio({ content }) {
  const { currentSong, music, handlePlay, isPlaying, handlePause } =
    useContext(MusicContext);
  const musicEl = music.find((el) => el.key === content.key);

  const [open, setOpen] = useState(false);

  const handleClick = () => {
    if (musicEl) handlePlay(musicEl);
  };

  return (
    <Box sx={{ position: "relative" }}>
      <CardMedia
        component="img"
        sx={{
          borderRadius: 3,
          minWidth: "300px",
          maxWidth: "100%",
          aspectRatio: "1/1",
        }}
        // onClick={handleClick}
        src={getContentArtwork(content)}
      />

      {/* Loading spinner when no musicEl */}
      {!musicEl && (
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <CircularProgress />
        </Box>
      )}

      {/* Play button at bottom right when musicEl exists */}
      {musicEl && (
        <Box
          sx={{
            position: "absolute",
            bottom: 70,
            right: 28,
            zIndex: 1,
          }}
        >
          {isPlaying && currentSong?.key === content.key ? (
            <IconButton
              onClick={handlePause}
              sx={{
                backgroundColor: "rgba(0,0,0,0.6)",
                "&:hover": {
                  backgroundColor: "rgba(0,0,0,0.7)",
                },
              }}
              size="large"
            >
              <Pause sx={{ color: "white" }} />
            </IconButton>
          ) : (
            <IconButton
              onClick={handleClick}
              sx={{
                backgroundColor: "rgba(0,0,0,0.6)",
                "&:hover": {
                  backgroundColor: "rgba(0,0,0,0.7)",
                },
              }}
              size="large"
            >
              <PlayArrow color="white" />
            </IconButton>
          )}
        </Box>
      )}

      <Box
        sx={{
          display: "flex",
          mt: 1,
          alignItems: "center",
          justifyContent: "flex-start",
          gap: 2,
        }}
      >
        <Typography
          color={currentSong?.key === content.key ? "secondary" : "inherit"}
        >
          {content.meta.title}
        </Typography>

        <Box id="handle-chat">
          <IconButton
            onClick={() => setOpen(true)}
            style={{ marginLeft: "5px" }}
          >
            <ChatBubbleOutline />
          </IconButton>
          <Typography variant="caption" style={{ marginRight: "5px" }}>
            {content.comment_count > 0 && content.comment_count}
          </Typography>
        </Box>
      </Box>
      <ActiveContentDrawer content={content} open={open} setOpen={setOpen} />
    </Box>
  );
}
