import axios from "axios";
import { useState } from "react";

import {
  Box,
  Button,
  Card,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";

import {
  Cancel,
  CheckCircle,
  Close,
  Login,
  Logout,
  MoreHoriz,
  Verified,
} from "@mui/icons-material";

import AppIcon from "@/assets/app-icon.svg";

import { API_BASE, currentUser } from "@/App";

import {
  getHeaders,
  handleLogout,
  setArtistKey,
  setCurrentUser,
  setToken,
} from "@/utils";

export default function CurrentAccountCard({ account }) {
  const [open, setOpen] = useState(false);

  return (
    <Card sx={{ my: 2, display: "flex", height: "60px" }}>
      <Box>
        <img
          src={account.user.meta?.profile || AppIcon}
          onClick={() => setOpen(true)}
          style={{ width: "60px", height: "60px", objectFit: "cover" }}
        />
      </Box>

      <Box
        onClick={() => setOpen(true)}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          px: 2,
        }}
      >
        <Typography variant="h7">
          {account.user.name || account.user.phone}
        </Typography>
        {currentUser.key == account.user.key ? (
          <IconButton>
            {currentUser.role == "artist" ? (
              <Verified color="secondary" />
            ) : (
              <CheckCircle />
            )}
          </IconButton>
        ) : (
          <IconButton onClick={() => setOpen(true)} sx={{ ml: 1 }}>
            <MoreHoriz color="info" />
          </IconButton>
        )}
      </Box>

      {/* <ActionDrawer account={account} open={open} setOpen={setOpen} /> */}
    </Card>
  );
}
