import axios from "axios";
import { useState } from "react";

import {
  Box,
  Button,
  Card,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";

import {
  Cancel,
  CheckCircle,
  Close,
  Login,
  Logout,
  MoreHoriz,
  Verified,
} from "@mui/icons-material";

import AppIcon from "@/assets/app-icon.svg";

import { API_BASE, currentUser } from "@/App";

import {
  getHeaders,
  handleLogout,
  setArtistKey,
  setCurrentUser,
  setToken,
} from "@/utils";

export default function AccountManagerCard({ account }) {
  const [open, setOpen] = useState(false);

  return (
    <Card sx={{ my: 2, display: "flex", height: "60px" }}>
      <Box>
        <img
          src={account.user.meta?.profile || AppIcon}
          onClick={() => setOpen(true)}
          style={{ width: "60px", height: "60px", objectFit: "cover" }}
        />
      </Box>

      <Box
        onClick={() => setOpen(true)}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          px: 2,
        }}
      >
        <Typography variant="h7">
          {account.user.name || account.user.phone}
        </Typography>
        {currentUser.key == account.user.key ? (
          <IconButton>
            {currentUser.role == "artist" ? (
              <Verified color="secondary" />
            ) : (
              <CheckCircle />
            )}
          </IconButton>
        ) : (
          <IconButton onClick={() => setOpen(true)} sx={{ ml: 1 }}>
            <MoreHoriz color="info" />
          </IconButton>
        )}
      </Box>

      <ActionDrawer account={account} open={open} setOpen={setOpen} />
    </Card>
  );
}

function ActionDrawer({ account, open, setOpen }) {
  // const handleLogin = () => {
  //   setCurrentUser(account.user);
  //   setToken(account.token);
  //   if (account.user.role == "artist") {
  //     setArtistKey(account.user.key);
  //   }
  //   window.location.href = "/";
  // };

  const handleRemoveManager = async () => {
    const confirmRemove = window.confirm(
      `Are you sure you want to remove ${account.user.name} as a manager?`
    );

    if (!confirmRemove) {
      return; // Exit the function if the user cancels the action
    }

    try {
      const endpoint = API_BASE + "/manager";
      const response = await axios.delete(endpoint, {
        data: {
          artist_key: currentUser.key,
          user_key: account.user.key,
        },
        ...getHeaders(),
      });
      window.location.reload();
    } catch (error) {
      console.error("Error removing manager:", error);
      alert(
        "An error occurred while trying to remove the manager. Please try again."
      );
    }
  };

  return (
    <Box>
      <Drawer
        anchor="bottom"
        open={open}
        onClose={() => setOpen(false)}
        PaperProps={{
          style: { borderRadius: "20px 20px 0 0" },
        }}
      >
        <Box sx={{ px: 2, py: 4 }}>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <IconButton onClick={() => setOpen(false)} sx={{ m: 1 }}>
              <Close />
            </IconButton>
          </Box>
          <List>
            {/* {currentUser.key != account.user.key && (
              <ListItem button key="login" onClick={handleLogin}>
                <ListItemIcon>
                  <Login />
                </ListItemIcon>
                <ListItemText
                  primary={`Log In as ${account.user.name || account.user.phone}`}
                />
              </ListItem>
            )} */}

            {currentUser.role == "artist" &&
              currentUser.key != account.user.key && (
                <ListItem
                  button
                  key="remove"
                  onClick={handleRemoveManager}
                  sx={{ my: 2 }}
                >
                  <Button
                    variant="outlined"
                    color="error"
                    fullWidth
                    style={{ borderRadius: "25px" }}
                    startIcon={<Cancel />}
                  >
                    Remove {account.user.name}
                  </Button>
                </ListItem>
              )}

            {currentUser.key === account.user.key && (
              <ListItem button key="logout" onClick={handleLogout}>
                <ListItemIcon>
                  <Logout />
                </ListItemIcon>
                <ListItemText primary="Logout" />
              </ListItem>
            )}
          </List>
        </Box>
      </Drawer>
    </Box>
  );
}
