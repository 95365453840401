import axios from "axios";
import { useState } from "react";

import {
  AppBar,
  Box,
  Card,
  CardMedia,
  IconButton,
  LinearProgress,
  Toolbar,
  Typography,
} from "@mui/material";

import {
  ArrowUpward,
  Close,
  PushPin,
  PushPinOutlined,
} from "@mui/icons-material";

import { API_BASE, authHeaders, currentUser } from "../../App";

import ContentTextField from "./ContentTextField";
// import PageAppBar from "./PageAppBar";

export default function Post({ file }) {
  const [loading, setLoading] = useState(false);
  const [text, setText] = useState("");
  const [featured, setFeatured] = useState(false);

  const handleSubmit = async (e) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("owner_key", currentUser.key);
    formData.append("artist_key", currentUser.key);
    formData.append("type", "image");

    formData.append("file", file);

    const meta = {
      text: text,
      featured: featured,
    };

    formData.append("meta", JSON.stringify(meta));

    try {
      setLoading(true);
      const endpoint = API_BASE + "/v1/post";
      const response = await axios.post(endpoint, formData, authHeaders);
      window.location.href = "/" + currentUser.key;
    } catch (error) {
      console.error("Error:", error);
      alert("Error: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box>
      <PageAppBar
        handleSubmit={handleSubmit}
        loading={loading}
        featured={featured}
        setFeatured={setFeatured}
      />

      <Box
        sx={{
          px: 2,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          gap: 4,
          mt: 2,
        }}
      >
        <ContentTextField text={text} setText={setText} />
        <Card style={{ borderRadius: "20px" }}>
          <CardMedia
            component="img"
            src={file ? URL.createObjectURL(file) : ""}
            style={{ borderRadius: "10px" }}
          />
        </Card>
      </Box>
    </Box>
  );
}

function PageAppBar({
  handleSubmit,
  loading,
  progress,
  featured,
  setFeatured,
}) {
  return (
    <AppBar
      position="sticky"
      elevation={0}
      style={{ background: "rgba(19,19,19,.9)" }}
    >
      <Toolbar
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <IconButton onClick={() => window.location.reload()} sx={{ pl: 0 }}>
          <Close style={{ color: "darkgray" }} />
        </IconButton>
        <Box sx={{ display: "flex", alignItems: "center", ml: 6 }}>
          <Typography
            variant="h6"
            style={{ color: "white", fontSize: "1.1rem" }}
          >
            New Photo
          </Typography>
        </Box>
        <Box style={{ display: "flex", alignItems: "center" }}>
          <Typography
            variant="caption"
            color={featured ? "secondary" : "darkgray"}
          >
            PIN
          </Typography>
          <IconButton
            onClick={() => setFeatured(!featured)}
            sx={{ mr: 2, transform: "rotate(45deg)" }}
          >
            {featured ? (
              <PushPin color="secondary" />
            ) : (
              <PushPinOutlined sx={{ color: "darkgray" }} />
            )}
          </IconButton>
          <IconButton
            size="small"
            style={{
              background: "#9A5AEF",
            }}
            onClick={handleSubmit}
            disabled={loading}
          >
            <ArrowUpward />
          </IconButton>
        </Box>
      </Toolbar>
      {loading && <LinearProgress />}
    </AppBar>
  );
}
