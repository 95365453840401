// FeaturedContainer.js
import { useState } from "react";

import { API_BASE, currentUser } from "@/App";
import ActiveContentDrawer from "@/components/ActiveContentDrawer";
import { ChatBubbleOutline, Star, StarBorder } from "@mui/icons-material";
import { Box, Card, CardMedia, IconButton, Typography } from "@mui/material";
import axios from "axios";
import ReactPlayer from "react-player";

export default function FeaturedImageVideo({ content }) {
  const [favorite, setFavorite] = useState(content.user_favorite);
  const [favoriteCount, setFavoriteCount] = useState(content.favorite_count);
  const [open, setOpen] = useState(false);

  const handleFavorite = async () => {
    setFavorite(!favorite);
    setFavoriteCount(favorite ? favoriteCount - 1 : favoriteCount + 1);
    const endpoint = API_BASE + "/event";
    const response = await axios.post(endpoint, {
      user_key: currentUser.key,
      content_key: content.key,
      type: favorite ? "unfavorite" : "favorite",
    });
  };

  return (
    <Box>
      {content.type == "image" && (
        <CardMedia
          component="img"
          image={content.meta.url}
          sx={{
            borderRadius: 3,
            minWidth: "300px",
            maxWidth: "100%",
            aspectRatio: "1/1",
          }}
        />
      )}

      {content.type == "video" && (
        <Card style={{ borderRadius: "15px" }}>
          <ReactPlayer
            url={content.meta.url}
            light={content.meta.cover}
            playing
            controls
            height="300px"
            width="300px"
          />
        </Card>
      )}

      <Box
        id="post-actions"
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          width: "100%",
          mt: 1,
        }}
      >
        <Box id="handle-favorite" sx={{ ml: -1, mr: 1 }}>
          <IconButton onClick={handleFavorite}>
            {favorite ? <Star color="secondary" /> : <StarBorder />}
          </IconButton>
          <Typography variant="caption">
            {favoriteCount > 0 && favoriteCount}
          </Typography>
        </Box>
        <Box id="handle-chat">
          <IconButton
            onClick={() => setOpen(true)}
            style={{ marginLeft: "5px" }}
          >
            <ChatBubbleOutline />
          </IconButton>
          <Typography variant="caption" style={{ marginRight: "5px" }}>
            {content.comment_count > 0 && content.comment_count}
          </Typography>
        </Box>
      </Box>
      <ActiveContentDrawer content={content} open={open} setOpen={setOpen} />
    </Box>
  );
}
