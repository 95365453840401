import axios from "axios";
import { useEffect, useState } from "react";

import {
  AppBar,
  Box,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  Drawer,
  IconButton,
  MenuItem,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";

import { Add, Close } from "@mui/icons-material";

import AppLogo from "@/fragments/AppLogo";
import { API_BASE, currentUser } from "@/App";
import AccountManagerCard from "./AccountManagerCard";
import ManagedAccountCard from "./ManagedAccountCard";
import CurrentAccountCard from "./CurrentAccountCard";

import { getHeaders, getToken } from "@/utils";

export default function Accounts() {
  const [loading, setLoading] = useState(false);

  const [managedAccounts, setManagedAccounts] = useState([]);
  const [accountManagers, setAccountManagers] = useState([]);

  const [open, setOpen] = useState(false);

  const currentAccount = {
    user: currentUser,
    token: getToken(),
  };

  useEffect(() => {
    const fetchAccounts = async () => {
      setLoading(true);
      const endpoint = `${API_BASE}/manager`;
      const response = await axios.get(endpoint, {
        params: {
          user_key: currentUser.key,
        },
      });
      setManagedAccounts(response.data.managed_accounts);
      setAccountManagers(response.data.account_managers);
      setLoading(false);
    };
    fetchAccounts();
  }, []);

  return (
    <Box>
      <PageAppBar />

      <Loading loading={loading} />

      {!loading && (
        <Box sx={{ p: 2 }}>
          <CurrentAccountCard account={currentAccount} />

          {managedAccounts.length > 0 && (
            <Card
              sx={{
                p: 2,
                my: 4,
                borderRadius: "10px",
                backgroundColor: "rgba(36,36,36,.9)",
              }}
            >
              <Typography variant="h6">Managed Accounts</Typography>
              {managedAccounts?.map((account, index) => (
                <Box key={index}>
                  <ManagedAccountCard account={account} />
                </Box>
              ))}
            </Card>
          )}

          {currentUser.role == "artist" && (
            <Card
              sx={{
                p: 2,
                my: 4,
                borderRadius: "10px",
                backgroundColor: "rgba(36,36,36,.9)",
              }}
            >
              <Typography variant="h6">Account Managers</Typography>
              {accountManagers?.map((account, index) => (
                <Box key={index}>
                  <AccountManagerCard account={account} />
                </Box>
              ))}
              <Button
                variant="outlined"
                onClick={() => setOpen(true)}
                fullWidth
                style={{ borderRadius: "25px" }}
                sx={{ my: 2 }}
                startIcon={<Add />}
              >
                Add Account Manager
              </Button>
              <AddAccountManagerDrawer open={open} setOpen={setOpen} />
            </Card>
          )}

          {currentUser.role == "artist" && <></>}
        </Box>
      )}
    </Box>
  );
}

function AddAccountManagerDrawer({ open, setOpen }) {
  const [countryCode, setCountryCode] = useState("+1"); // default to US [+1
  const [phone, setPhone] = useState("");
  const [confirm, setConfirm] = useState(false);
  const [loading, setLoading] = useState(false);

  const formattedPhone = () => {
    return countryCode + phone;
  };

  const handleAddAcount = async () => {
    setLoading(true);
    const endpoint = API_BASE + "/manager";
    const response = await axios.post(
      endpoint,
      {
        artist_key: currentUser.key,
        phone: formattedPhone(),
      },
      getHeaders()
    );
    window.location.reload();
  };

  return (
    <Box>
      <Drawer
        anchor="bottom"
        open={open}
        onClose={() => setOpen(false)}
        PaperProps={{
          style: { borderRadius: "20px 20px 0 0" },
        }}
      >
        <Box sx={{ px: 2, py: 4 }}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Add Account Manager
          </Typography>
          <Box style={{ display: "flex", justifyContent: "space-between" }}>
            <TextField
              select
              value={countryCode}
              onChange={(e) => setCountryCode(e.target.value)}
              variant="outlined"
              style={{ width: "110px", marginRight: "10px" }}
            >
              <MenuItem value="+1">+1</MenuItem>
              {/* <MenuItem value="+44">+44</MenuItem> */}
            </TextField>
            <TextField
              label="Phone Number"
              variant="outlined"
              type="telephone"
              value={phone}
              onChange={(e) => setPhone(e.target.value.replace(/[^0-9]/g, ""))}
              fullWidth
              inputProps={{
                inputMode: "numeric",
                pattern: "[0-9]*",
              }}
              // submit on enter
              onKeyDown={(e) => {
                if (e.key === "Enter" && phone.length >= 10) {
                  handleAddAcount();
                }
              }}
            />
          </Box>
          <Box
            style={{
              display: "flex",
              alignItems: "flex-start",
              width: "100%",
            }}
            sx={{ my: 4 }}
          >
            <Checkbox
              checked={confirm}
              onChange={(e) => setConfirm(e.target.checked)}
              sx={{ pt: 0.5, pr: 2 }}
            />
            <Typography variant="body1" color="darkgray">
              I understand that this user will be able to perform all actions on
              my behalf.
            </Typography>
          </Box>

          <Button
            variant="contained"
            size="large"
            fullWidth
            onClick={handleAddAcount}
            disabled={phone.length < 10 || !confirm || loading}
            startIcon={<Add />}
            sx={{ my: 2 }}
            style={{ borderRadius: "25px" }}
          >
            Add Account Manager
          </Button>
        </Box>
      </Drawer>
    </Box>
  );
}

function PageAppBar() {
  return (
    <AppBar
      position="sticky"
      elevation={0}
      style={{ background: "rgba(19,19,19,.9)" }}
    >
      <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
        <IconButton onClick={() => (window.location.href = "/")}>
          <Close />
        </IconButton>
        <AppLogo />
        <Box style={{ width: "40px" }} />
      </Toolbar>
    </AppBar>
  );
}

function Loading({ loading }) {
  return (
    <>
      {loading && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "60vh",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </>
  );
}
