// FeaturedContainer.js

import FeaturedAudio from "@/components/FeaturedAudio";
import FeaturedImageVideo from "@/components/FeaturedImageVideo";
import { Box, Typography } from "@mui/material";

export default function FeaturedContainer({ artistContent }) {
  return (
    <Box
      sx={{
        background:
          "linear-gradient(180deg, rgba(36,36,36,1) 0%, rgba(18,18,18,0) 50%)",
        pt: 4,
        pb: 6,
        px: 2,
      }}
    >
      <Typography variant="h6" sx={{ mb: 4, fontWeight: 800 }}>
        Featured
      </Typography>

      {/* Container for horizontal scroll */}
      <Box
        sx={{
          display: "flex",
          overflowX: "scroll",
          scrollbarWidth: "none" /* Firefox */,
          "&::-webkit-scrollbar": {
            /* WebKit */ display: "none",
          },
        }}
      >
        {artistContent.map((content, index) => (
          <Box key={index} sx={{ mr: 4 }}>
            {["image", "video"].includes(content.type) && (
              <FeaturedImageVideo content={content} />
            )}

            {content.type == "audio" && <FeaturedAudio content={content} />}
          </Box>
        ))}
      </Box>
    </Box>
  );
}
